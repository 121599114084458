<template>
	<div class="main-content">
		<div v-if="!is.showing.paymentInstruction">
			<ktv-breadcrumb
				:folder="$t('Premium Points Detail', 'Premium Points Detail')"
				:page="$t('Disburse', 'Disburse')"
				:title="$t('Disburse Premium Points', 'Disburse Premium Points')"
			/>
			<b-row>
				<b-col md="12 mb-30">
					<b-row class="mb-4">
						<b-col>
							<router-link v-b-tooltip.hover :to="{ name: 'Premium Points Disburse List' }">
								<ktv-back :label="$t('Back to disburse premium list', 'Back to disburse premium list')" />
							</router-link>
						</b-col>
					</b-row>
					<div>
						<b-row>
							<b-col>
								<h2>{{ $t("Premium Points Disburse Generated", "Premium Points Disburse Generated") }}</h2>
							</b-col>
						</b-row>
						<b-row class="px-16">
							<div class="text-14">
								<span class="text-gray-400">{{ $t("Unit", "Unit") }}: </span>
								<span class="font-weight-600 text-gray-500">{{ disburseUnit }} </span>
							</div>
							<div class="text-14 ml-4">
								<span class="text-gray-400">{{ $t("Hub Area", "Hub Area") }}: </span>
								<span class="font-weight-600 text-gray-500">{{ disburseHubArea }} </span>
							</div>
							<div class="text-14 ml-4">
								<span class="text-gray-400">{{ $t("Premium Period", "Premium Period") }}: </span>
								<span class="font-weight-600 text-gray-500">{{ disburseStartDate }} - {{ disburseEndDate }}</span>
							</div>
						</b-row>
						<b-row class="mt-16">
							<b-col class="col-md-auto text-14">
								<div class="text-gray-400">{{ $t("Farmer", "Farmer") }}</div>
								<div class="font-weight-600 text-gray-500">{{ sumFarmer | Number }}</div>
							</b-col>
							<b-col class="col-md-auto text-14">
								<div class="text-gray-400">{{ $t("Netto (kg)", "Netto (kg)") }}</div>
								<div class="font-weight-600 text-gray-500">{{ sumNetto | Number }}</div>
							</b-col>
							<b-col class="col-md-auto text-14">
								<div class="text-gray-400">{{ $t("Premium Points", "Premium Points") }}</div>
								<div class="font-weight-600 text-gray-500">{{ Math.round(sumPremiumPoints) | Number }}</div>
							</b-col>
							<b-col class="col-md-auto text-14">
								<div class="text-gray-400">
									<span class="mr-1">{{ $t("Total Fee", "Total Fee") }}</span>
									<img id="total-fee" :src="icons.info" />
									<b-tooltip target="total-fee">
										{{
											`${$t("Fee", "Fee")} ${idrFilter(Math.round(disburseSumFee / disburseSumFarmer))}/${$t(
												"Farmer",
												"Farmer",
											).toLowerCase()}`
										}}
									</b-tooltip>
								</div>
								<div class="font-weight-600 text-gray-500">{{ Math.round((disburseSumFee / disburseSumFarmer) * sumFarmer) | Rupiah }}</div>
							</b-col>
							<b-col class="col-md-auto text-14">
								<div class="font-weight-600 text-gray-500">{{ $t("Total Payment", "Total Payment") }}</div>
								<div class="font-weight-600 text-gray-500">{{ Math.round((disburseSumFee / disburseSumFarmer) * sumFarmer) | Rupiah }}</div>
							</b-col>
							<b-col />
						</b-row>
						<div class="mt-32">
							<ktv-table
								:columns="columns"
								:filter="false"
								:is-loading="is.loading"
								:rows="filteredData"
								:search-enabled="false"
								:search-on-enter="false"
								:search-placeholder="$t('Search by farmer name or id', 'Search by farmer name or id')"
								:total-rows="disburseData ? filteredData.length : 0"
								min-height="250px"
								mode="client"
								@on-search="onSearch"
							>
								<template #columns="{ props }">
									<span v-if="props.column.field === 'checkbox'">
										<b-form-group>
											<b-form-checkbox v-model="is.checkedAll" />
										</b-form-group>
									</span>
									<span v-else>{{ $t(props.column.label, props.column.label) }}</span>
								</template>
								<template #rows="{ props }">
									<span v-if="props.column.field === 'number'">{{ props.row.originalIndex + 1 }}</span>
									<span v-else-if="props.column.field === 'checkbox'">
										<b-form-group>
											<b-form-checkbox v-model="checked[props.row.invoiceNumber]" />
										</b-form-group>
									</span>
									<span v-else-if="props.column.field === 'farmerID'">{{ props.row.farmerID ? props.row.farmerID : "-" }}</span>
									<span v-else-if="props.column.field === 'farmerName'">{{ props.row.farmerName ? props.row.farmerName : "-" }}</span>
									<span v-else-if="props.column.field === 'nett'">
										{{ props.row.nett && props.row.nett >= 0 ? idrFilter(props.row.nett) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'premium'">
										{{ props.row.premiumFarmer >= 0 ? IDRFilter(Math.round(props.row.premiumFarmer)) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'premiumFarmer'">
										{{ props.row.premiumFarmer >= 0 ? idrFilter(Math.round(props.row.premiumFarmer)) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'status'">{{ props.row.status ? props.row.status : "-" }}</span>
								</template>
							</ktv-table>
						</div>
						<div class="mt-32" align="center">
							<ktv-button
								:disabled="sumFarmer <= 0"
								:loading="is.loading"
								:text="$t('Continue to Payment', 'Continue to Payment')"
								icon="credit-card"
								@click="showPaymentInstruction()"
							/>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
		<payment-instruction
			v-if="is.showing.paymentInstruction"
			:disburse="disburse"
			:payment-method-options="paymentMethodOptions"
			:show="is.showing.paymentInstruction"
			@close="closePaymentInstruction()"
		/>
	</div>
</template>
<script>
	import { contains, each, findWhere, filter, keys, map, pick, reduce } from "underscore"
	import { KtvBack, KtvButton, KtvTable } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"
	import PaymentInstruction from "./_PaymentInstruction"

	import infoIcon from "@/assets/images/icon/info-gray.svg"

	const icons = {
		info: infoIcon,
	}

	export default {
		name: "PremiumPointsDisburse",
		metaInfo: {
			title: "View Premium Points Disburse",
		},
		components: { KtvBack, KtvButton, KtvTable, PaymentInstruction },
		data() {
			return {
				checked: null,
				columns: [
					{
						field: "checkbox",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "50px",
					},
					{
						field: "number",
						html: true,
						label: "Number",
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "farmerID",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "farmerName",
						label: "Farmer Name",
						thClass: "text-left",
						width: "200px",
					},
					{
						field: "nett",
						label: "Netto (kg)",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "premium",
						label: "Premium",
						tdClass: "text-right",
						thClass: "text-right",
						width: "150px",
					},
					{
						field: "premiumFarmer",
						label: "Premium Points",
						tdClass: "text-right",
						thClass: "text-right",
						width: "150px",
					},
					{
						field: "status",
						label: "Status",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
				],
				disburse: {
					accordionAttributes: [],
					accountName: "PT Koltiva", // TO-DO: Koltiva Name
					accountNumber: null,
					art23: 0,
					destinationId: "261", // TO-DO: Koltiva ID
					destinationName: "PT Koltiva", // TO-DO: Koltiva Name
					destinationType: "Koltiva",
					note: null,
					paymentInstructions: [],
					paymentMethodId: 2,
					subTotal: 0,
					totalPayment: 0,
					transactionId: null,
					vat: 0,
					virtualAccount: null,
				},
				icons: icons,
				is: {
					checkedAll: false,
					loading: false,
					showing: {
						paymentInstruction: false,
					},
				},
				paymentMethodOptions: [],
				searchText: null,
			}
		},
		computed: {
			...mapGetters({
				disburseData: "PREMIUMPOINT/disburseData",
				disburseEndDate: "PREMIUMPOINT/disburseEndDate",
				disburseHubArea: "PREMIUMPOINT/disburseHubArea",
				disburseId: "PREMIUMPOINT/disburseId",
				disburseStartDate: "PREMIUMPOINT/disburseStartDate",
				disburseSumFarmer: "PREMIUMPOINT/disburseSumFarmer",
				disburseSumFee: "PREMIUMPOINT/disburseSumFee",
				disburseSumNetto: "PREMIUMPOINT/disburseSumNetto",
				disburseSumPremiumPoints: "PREMIUMPOINT/disburseSumPremiumPoints",
				disburseUnit: "PREMIUMPOINT/disburseUnit",
				token: "AUTHDATA/token",
				userId: "AUTHDATA/userId",
			}),
			...mapState("PAYMENT", ["paymentMethodList"]),
			amount() {
				return (this.disburseSumFee / this.disburseSumFarmer) * this.sumFarmer
			},
			checkedArr() {
				return keys(
					pick(this.checked, function (value) {
						return value
					}),
				)
			},
			checkedInvoiceNumbers() {
				return map(this.checkedArr, (number) => {
					const invoice = findWhere(this.disburseData, { invoiceNumber: number })

					return {
						invoiceNo: number,
						premiumFarmer: invoice.premiumFarmer,
						nett: invoice.nett,
					}
				})
			},
			checkedData() {
				return filter(this.disburseData, (disburse) => {
					return contains(this.checkedArr, disburse.invoiceNumber)
				})
			},
			filteredData() {
				return this.searchText
					? filter(this.disburseData, (disburse) => {
						const farmerName = String(disburse.farmerName).toLowerCase()
						const farmerID = String(disburse.farmerID).toLowerCase()
						const searchText = String(this.searchText).toLowerCase()

						return farmerName.indexOf(searchText) > -1 || farmerID.indexOf(searchText) > -1
					})
					: this.disburseData
			},
			sumFarmer() {
				return this.checkedArr ? this.checkedArr.length : 0
			},
			sumNetto() {
				const totalNetto = reduce(
					this.checkedData,
					(total, data) => {
						return parseFloat(data.nett, 10) + total
					},
					0,
				)

				return totalNetto
			},
			sumPremiumPoints() {
				const totalPremiumPoints = reduce(
					this.checkedData,
					(total, data) => {
						return parseFloat(data.premiumFarmer, 10) + total
					},
					0,
				)

				return totalPremiumPoints
			},
		},
		watch: {
			"is.checkedAll": {
				handler() {
					each(this.disburseData, (disburse) => {
						this.checked = {
							...this.checked,
							[disburse.invoiceNumber]: this.is.checkedAll,
						}
					})
				},
				immediate: true,
			},
			disburseData: {
				deep: true,
				handler() {
					if (this.disburseData && this.disburseData.length > 0) {
						this.is = {
							...this.is,
							checkedAll: true,
						}
					} else {
						this.$swal(
							`${this.$t("Data not found", "Data not found")}!`,
							this.$t("Please make sure input the right filter to generate data", "Please make sure input the right filter to generate data"),
							"error",
						)

						this.$router.push({
							name: "Premium Points Disburse List",
						})
					}
				},
				immediate: true,
			},
			"paymentMethodList.premiumPoint": {
				deep: true,
				handler() {
					this.getPaymentMethodOptions()
				},
				immediate: true,
			},
			token: {
				handler(newValue, oldValue) {
					if (newValue && newValue !== oldValue) {
						this.getPaymentReference("premium-point").then(() => {
							this.getPaymentReference("premium-point")
						})
					}
				},
				immediate: true,
			},
		},
		beforeDestroy() {
			this.disburse = {
				accordionAttributes: [],
				accountName: "PT Koltiva", // TO-DO: Koltiva Name
				accountNumber: null,
				art23: 0,
				destinationId: "261", // TO-DO: Koltiva ID
				destinationName: "PT Koltiva", // TO-DO: Koltiva Name
				destinationType: "Koltiva",
				note: null,
				paymentInstructions: [],
				paymentMethodId: 2,
				subTotal: 0,
				totalPayment: 0,
				transactionId: null,
				vat: 0,
				virtualAccount: null,
			}

			this.searchText = null
			this.resetDisburseList()
		},
		methods: {
			...mapActions({
				checkServiceCharge: "PAYMENT/checkServiceCharge",
				createDisbursePremiumPointChecked: "PREMIUMPOINT/createDisbursePremiumPointChecked",
				getPaymentReference: "PAYMENT/getPaymentReference",
				resetDisburseList: "PREMIUMPOINT/resetDisburseList",
				submitPayment: "PAYMENT/submitPayment",
				updateDisburseStatusFee: "PREMIUMPOINT/updateDisburseStatusFee",
			}),
			closePaymentInstruction() {
				this.is.showing = {
					...this.is.showing,
					paymentInstruction: false,
				}
			},
			getPaymentMethodOptions() {
				this.paymentMethodOptions = map(this.paymentMethodList.premiumPoint, (paymentMethod) => {
					return {
						text: `${paymentMethod.PaymentLabel} - ${paymentMethod.PaymentMethod}`,
						value: parseInt(paymentMethod.PaymentMethodID, 10),
					}
				})
			},
			idrFilter(amount) {
				return this.$options.filters.Number(amount)
			},
			IDRFilter(amount) {
				return this.$options.filters.Rupiah(amount)
			},
			onSearch($event) {
				const self = this

				this.is = {
					...this.is,
					loading: true,
				}
				this.searchText = $event

				setTimeout(() => {
					self.is = {
						...self.is,
						loading: false,
					}
				}, 500)
			},
			showPaymentInstruction() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.createDisbursePremiumPointChecked({
					createdBy: this.userId,
					data: this.checkedInvoiceNumbers,
					endDate: this.disburseEndDate,
					startDate: this.disburseStartDate,
				}).then((disburse) => {
					this.disburse = {
						...this.disburse,
						disburseId: disburse.disburseId,
					}

					this.checkServiceCharge({
						amount: Math.round(disburse.sumFee),
						paymentMethodId: 2,
						type: "premium-point",
					}).then((serviceCharge) => {
						this.submitPayment({
							accountName: this.disburse.accountName,
							accountNumber: process.env.VUE_APP_KOLTIVA_ACCOUNT_NUMBER,
							amount: Math.round(disburse.sumFee),
							bankCode: "002",
							bankName: "BRI",
							destinationId: this.disburse.destinationId,
							destinationName: this.disburse.destinationName,
							destinationType: this.disburse.destinationType,
							id: `${parseInt(String(this.disburse.disburseId), 10)}-fee`,
							note: "premium-point-disburse",
							paymentMethodId: 2,
							serviceCharge: 0, // paym-559: service charge removed
							serviceChargeType: serviceCharge.ServiceChargeType,
							totalServiceCharge: 0, // paym-559: service charge removed
							type: "premium-point",
						})
							.then((payment) => {
								this.updateDisburseStatus({ ...disburse, sumFee: Math.round(disburse.sumFee) }, payment)
							})
							.catch(() => {
								this.is = {
									...this.is,
									loading: false,
								}
							})
					})
				})
			},
			async updateDisburseStatus({ art23, subTotal, sumFee, vat }, payment) {
				let isSuccess = false

				for (let i = 0; i < 3; i++) {
					if (!isSuccess) {
						// eslint-disable-next-line no-await-in-loop
						await this.updateDisburseStatusFee({
							disburseId: parseInt(String(this.disburse.disburseId), 10),
							flag: 3,
							virtualAccount: payment.PaymentDetail[0].VirtualAccount,
						})
							// eslint-disable-next-line no-loop-func
							.then((disburseStatusFee) => {
								if (disburseStatusFee.id && parseInt(disburseStatusFee.id, 10) !== 0) {
									isSuccess = true
								}
							})
							.catch(() => {
								this.is = {
									...this.is,
									loading: false,
								}
							})
					}
				}

				if (isSuccess) {
					this.disburse = {
						...this.disburse,
						accordionAttributes: map(payment.PaymentDetail[0].PaymentInstruction, (_a, index) => {
							return {
								class: "i-Arrow-Down",
								"v-b-toggle": `'accordion-${index}'`,
							}
						}),
						art23: art23,
						expiredDate: payment.PaymentDetail[0].ExpiredDate,
						paymentInstructions: payment.PaymentDetail[0].PaymentInstruction,
						subTotal: subTotal,
						totalPayment: sumFee,
						vat: vat,
						virtualAccount: payment.PaymentDetail[0].CompanyCode + payment.PaymentDetail[0].VirtualAccount,
					}

					this.is.showing = {
						...this.is.showing,
						paymentInstruction: true,
					}
					this.is = {
						...this.is,
						loading: false,
					}
				} else {
					this.is = {
						...this.is,
						loading: false,
					}

					this.$swal(
						`${this.$t("Error", "Error")}!`,
						this.$t("Unable to update disburse status. Please try again later", "Unable to update disburse status. Please try again later"),
						"error",
					)
				}
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
<style lang="sass">
	.custom-checkbox
		.custom-control-label
			&::after, &::before
				top: -0.3rem
				left: 0

	.custom-control
		padding-left: 0
</style>
